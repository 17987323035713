import React from 'react';

import { store } from '@src/bootstrap/store';
import { Content, StakingAnalytics, StakingPools, Meta } from '@src/components';
import { useStakingState } from '@src/hooks';
import { setPools } from '@src/features/staking';
import { getStakingPools } from '@src/utils/getters';
import { StakingPool } from '@src/ts/interfaces';
import { staking_versions } from '@src/ts/types';
import { Banner } from '@src/components/Banner';

const Staking: React.FC<{
    pools: StakingPool[];
}> = ({ pools }) => {
    useStakingState(pools);

    return (
        <Content>
            <Meta title="Staking" />

            <Banner />

            <StakingAnalytics />

            <StakingPools />
        </Content>
    );
};

export async function getServerSideProps(): Promise<{
    props: {
        pools: StakingPool[];
        error?: string;
    };
}> {
    try {
        const pools = (
            await Promise.all(
                staking_versions.map((version) => getStakingPools(version)),
            )
        ).reduce((acc, curr) => [...acc, ...curr], []);

        store.dispatch(setPools(pools));

        return {
            props: {
                pools,
            },
        };
    } catch (e) {
        return {
            props: {
                pools: [],
                error: e.message,
            },
        };
    }
}

export default Staking;
